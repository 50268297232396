import { IAudienceBasedSearchConfiguration } from "../Interfaces/IAudienceBasedSearchConfiguration";

export class Configuration implements IAudienceBasedSearchConfiguration {
    ButtonText = "Submit";
    VisitorTypes = [
        {
            Text: 'I\'m a patient',
            Val: 'patient',
            Actions: [
                {
                    Text: 'Patient Action 1',
                    Val: 'patientAction1',
                    Url: 'https://www.google.com'
                },
                {
                    Text: 'Patient Action 2',
                    Val: 'patientAction2',
                    Url: 'https://www.msn.com'
                }
            ]
        },
        {
            Text: 'I\'m a visitor',
            Val: 'visitor',
            Actions: [
                {
                    Text: 'Visitor Action 1',
                    Val: 'visitorAction1',
                    Url: 'https://www.target.com'
                },
                {
                    Text: 'Visitor Action 2',
                    Val: 'visitorAction2',
                    Url: 'https://www.walmart.com'
                }
            ]
        },
        {
            Text: 'I\'m a provider',
            Val: 'provider',
            Actions: [
                {
                    Text: 'Provider Action 1',
                    Val: 'providerAction1',
                    Url: 'https://www.bestbuy.com'
                },
                {
                    Text: 'Provider Action 2',
                    Val: 'providerAction2',
                    Url: 'https://www.gizmodo.com'
                }
            ]
        }
    ];
    VisitorTypeSelectTemplate = "../node_modules/audience-based-search/templates/visitor-type-select.htm";
    ActionSelectTemplate = "../node_modules/audience-based-search/templates/action-select.htm";
    ButtonTemplate = "../node_modules/audience-based-search/templates/button.htm";
    VisitorTypeSelectLabel = "I am a";
    ActionSelectLabel = "and like to";
}